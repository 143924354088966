export default [
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/contactus"),
    meta: {
      layout: "full",
      meta: { requiresAuth: false },
    },
  },

  {
    path: "/allcontacts",
    name: "allcontacts",
    component: () => import("@/views/Allcontacts.vue"),
    meta: {
      pageTitle: "Contacts",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Manage Contacts",
          active: true,
        },
      ],
    },
  },
];
