import Vue from "vue";
import VueRouter from "vue-router";
import errorRoute from "./modules/error_route";
import mainRoute from "./modules/main_route";
import aboutRoute from "./modules/aboutus_route";
import servicesRoute from "./modules/services_route";
import contactRoute from "./modules/contactus_route";
import careersRoute from "./modules/careers_route";
import projectRoute from "./modules/projects_route";
import adminRoute from "./modules/admin_route";
import loginRoute from "./modules/login_route";
import locationsRoute from "./modules/locations_route";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...errorRoute,
    ...mainRoute,
    ...aboutRoute,
    ...servicesRoute,
    ...contactRoute,
    ...careersRoute,
    ...projectRoute,
    ...adminRoute,
    ...loginRoute,
    ...locationsRoute,
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
