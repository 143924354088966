export default [
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/admin.vue"),
    meta: {
      pageTitle: "Home",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
];
