export default [
  {
    path: "/projects",
    name: "projects",
    component: () => import("@/views/projects"),
    meta: {
      layout: "full", 
    requiresAuth: false ,
    },
  },
];
