export default [
  {
    path: "/careers",
    name: "careers",
    component: () => import("@/views/careers"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/allcareers",
    name: "allcareers",
    component: () => import("@/views/Allcareers.vue"),
    meta: {
      pageTitle: "Careers",
      requiresAuth: true,
      breadcrumb: [
        {
          text: "Manage Careers",
          active: true,
        },
      ],
    },
  },
];
