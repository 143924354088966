export default [
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/login"),
      meta: {
        layout: "full", 
        requiresAuth: false ,
      },
    },
  ];
  