export default [
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/Aboutus"),
    meta: {
      layout: "full",
      requiresAuth: false,
    },
  },
];
