export default [
  {
    path: "/retails",
    name: "retails",
    component: () => import("@/views/Services/retailservices.vue"),
    meta: {
      layout: "full",
      requiresAuth: false,
    },
  },
  {
    path: "/wholeseller",
    name: "wholeseller",
    component: () => import("@/views/Services/wholeseller.vue"),
    meta: {
      layout: "full",
      requiresAuth: false,
    },
  },
  {
    path: "/management",
    name: "management",
    component: () => import("@/views/Services/management.vue"),
    meta: {
      layout: "full",
      requiresAuth: false,
    },
  },
  {
    path: "/investors",
    name: "investors",
    component: () => import("@/views/Services/investors.vue"),
    meta: {
      layout: "full",
      requiresAuth: false,
    },
  },
  {
    path: "/developers",
    name: "developers",
    component: () => import("@/views/Services/developers.vue"),
    meta: {
      layout: "full",
      requiresAuth: false,
    },
  },
];
